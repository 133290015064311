<template>
  <!-- 个人端底部组件 -->
  <div class="feet">
    <div class="el-feet-center">
      <div class="el-feet-center-one">
        <div class="el-feet-item">
          <div class="text-main font-weight-bold el-font-lighter">企业服务</div>
          <div>新闻质询</div>
          <div>纺织大学堂</div>
        </div>
        <div class="el-feet-item pointer">
          <div class="text-main font-weight-bold el-font-lighter" @click="navTo('/protocol',2)">使用与帮助</div>
          <div @click="navTo('/protocol',0)">用户协议</div>
          <div @click="navTo('/protocol',1)">隐私政策</div>
          <div @click="navTo('/protocol',2)">使用帮助</div>
        </div>
        <div class="el-feet-item">
          <div class="text-main font-weight-bold el-font-lighter">联系干纺织</div>
          <div>杭州菁联网络技术有限公司</div>
          <div>浙江省杭州市萧山区金城路540号3幢1501-3</div>
          <div>0571-82635788 &nbsp; 周一至周五 &nbsp; 8:30-17:30</div>
        </div>
        <div class="el-feet-item-img">
          <img src="../assets/forget/1.jpg" alt="" />
          <div>干纺织微信小程序</div>
        </div>
        <div class="el-feet-item-img">
          <img src="../assets/forget/4.jpg" alt="" />
          <div>干纺织微信服务号</div>
        </div>
        <div class="el-feet-item-img">
          <img src="../assets/forget/24.png" alt="" />
          <div>干纺织企业微信客服</div>
        </div>
      </div>
    </div>
    <div class="feet-bottom">
      <span>Copyright ©️2022</span>
      <!-- <div>浙ICP备2022015771号-1</div> -->
	  <div><a style="text-decoration: none;" href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow">浙ICP备2022015771号-1</a></div>
      <span>电子营业执照</span>
      <img src="../assets/forget/22.png" alt="" />
      <span>人力资源许可服务证</span>
      <img src="../assets/forget/23.png" class="img-one" alt="" />
      <!-- <span>公安备案号202200001号</span> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import maskSever from "./server.vue";
export default {
  computed: {
    // 2. 解构状态作为计算属性
    // {{setObj.copy}} &nbsp;{{setObj.web_url}} &nbsp;{{setObj.web_name}} &nbsp;电话：{{setObj.kf_phone}} &nbsp;{{setObj.icp}}
    ...mapState(["setObj"]),
  },
  components: { maskSever },

  data() {
    return {};
  },

  created() {},

  mounted() {},

  methods: {
    navTo(path,type){
      this.$router.push({ path,query:{type} });
    }
  },
};
</script>
<style lang="less" scoped>
.feet {
  width: 100%;

  .el-feet-center {
    height: 309px;
    background: #ffffff;
    width: 100%;
    
    .el-feet-center-one{
      height: 100%;
      display: flex;
      justify-content: center;
    }
    // justify-content: space-between;
    .el-feet-item {
      font-weight: 400;
      color: #8d92a1;
      font-size: 14px;
      margin-top: 80px;
      margin-right: 74px;
      white-space: nowrap;
      div:first-child {
        margin-bottom: 15px;
      }
      div {
        margin-bottom: 10px;
      }
    }
    .el-feet-item:last-child {
      margin-right: 78px;
    }
    .el-feet-item-img {
      font-weight: 400;
      color: #8d92a1;
      font-size: 14px;
      margin-top: 80px;
      margin-right: 40px;
      img {
        width: 127px;
        height: 127px;
        margin-bottom: 10px;
      }
    }
    .el-feet-item-img:last-child {
      margin-right: 0;
    }
  }
  .feet-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 57px;
    font-weight: 400;
    color: #999999;
    font-size: 14px;
    img {
      width: 28px;
      height: 34px;
      margin: 0 9px 0 29px;
    }
    .img-one {
      width: 22px;
      height: 24px;
      margin: 0 10px 0 30px;
    }
    div {
      margin: 0 30px;
    }
  }
}
</style>
