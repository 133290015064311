<template>
  <!-- 弹窗组件 -->
  <div>
    <div v-if="status">
      <div
        class="position-fixed top-0 left-0 right-0 bottom-0"
        style="background-color: rgba(0, 0, 0, 0.5); z-index: 1000"
        @click.stop="hide"
      ></div>
      <div class="mask-company animated faster fadeIn">
        <div class="row-end">
          <i class="el-icon-close pointer" @click="hide"></i>
        </div>
        <div class="user-protocol">
          <div>干纺织增值服务协议</div>
          <h2>服务条款的确认和接纳</h2>
          干纺织的所有权归杭州菁联网络技术有限公司所有，该个人用户增值服务服务内容及对其的解释权由杭州菁联网络技术有限公司所有。（以下简称“干纺织”）用户如需使用该项服务，须仔细阅读并严格遵守干纺织制定的《干纺织个人用户增值服务条款》（以下简称“本条款”），完成注册程序和缴纳所需费用之后，才能享受干纺织提供的个人用户增值服务（以下简称“服务”）。
          <h2>服务所需要的设备和其他条件</h2> 为使用干纺织个人用户增值服务，用户必须：<br/> (1)
          拥有接入互联网的硬件设备和软件工具，包括但不限于计算机、调制解调器、有关软件和设置，或者其它必备的上网装置和进行有关设置。<br/>
          (2) 支付接入互联网的电话线路占用费用、网络服务费用以及增值服务费用。 
          <h2>服务介绍</h2>
          干纺织运用自己的技术力量和存储数据为用户提供有偿服务。
          考虑到个人用户增值服务的重要性和安全性，用户：<br/> (1)
          在注册时，需提供真实有效的个人邮箱和手机号。<br/>  (2)
          在填写简历时，需按页面提示提供真实、准确的个人资料。<br/>  (3)
          如个人资料有任何变动，用户有必要及时更新自己的信息。<br/>  (4)
          需同意各项条款且具备确认本条款和接受收费增值服务的相应民事行为能力。<br/>  (5)
          需支付相应的费用。
          如果用户违反上述规定，干纺织保留终止或结束该用户使用服务的权利。
          <h2>服务购买、开通、取消和终止 </h2>(1)
          用户接受本条款并完成相应程序，缴纳相应服务费用后，即成为服务的合法使用者。 <br/>(2)
          干纺织的服务购买后，除“MAST测评”服务填写完成后即可开通外，其余服务均需在付款成功且用户正确设置后的第二日开通。
          <br/>
          (3)
          用户在购买服务生成订单后的30日内未缴纳相应款项，视作自动放弃该次交易，干纺织保留对交易的自动关闭权。
          <br/>
          (4) 在服务有效期内，用户不得重复购买服务。 <br/>(5)
          蓄意重复多次购买但不进行付款的行为将被视作恶意购买行为，干纺织有权冻结该用户账号并终止所有服务。<br/>
          (6) 服务费用缴纳之后，在任何情况下，都不进行退款或者延期处理。 <br/>(7)
          干纺织保留对服务和本条款的变更权。 
          <h2>服务条款的完善和修改</h2>
          干纺织保留在必要时对个人用户增值服务条款（包括但不限于本条款）进行不断完善和修改的权利。干纺织个人增值服务服务条款一旦发生变动，将以公告的方式通知用户，，为用户提供优质的服务保证。
          当条款变更后，用户可以作如下选择： <br/>(1)
          不再继续使用干纺织的服务，已收款项不进行退款 <br/>(2)
          如用户不作出任何表示并继续使用服务，则视为接受条款的变更。<br/> (3)
          如用户同意条款变更的内容，当发生争议时，以最新的条款为准。 <h2>服务保证制度</h2>
          干纺织会努力完善和提升服务质量，对系统的稳定性、防灾害性、用户信息的安全性、快速性等方面做最大努力。
          <br/>
          由于不可抗力造成干纺织无法继续提供服务，干纺织不承担责任。“不可抗力”是指干纺织不能合理控制、不可预见或即使预见亦无法避免的事件，该事件妨碍、影响或延误干纺织为用户提供服务。该事件包括但不限于政府行为、骨干通信线路故障、地震、台风、洪水、火灾及其它天灾、战争或任何其它类似事件。
          <h2>用户责任</h2>
          由于用户过错原因导致干纺织损失（包括但不限于因此干纺织需对第三方承担的损失）需由用户进行赔偿。
          <h2>保障</h2>
          用户同意保障和维护干纺织的利益，并承诺支付由于用户违反本条款而引起的费用（包括但不限于赔偿费、律师费，因其他人使用用户的帐号和密码而发生的法律和经济责任）。
          <h2>干纺织通知</h2>
          用户在使用服务的同时，干纺织会以公告的方式通知用户条款的变更、服务的升级或其他干纺织发布的重要信息。
          <h2>所有权</h2>
          干纺织定义的“内容”包括：文字、图片、声音、视频和图表；在广告中的全部内容；电子邮件的全部内容；干纺织为用户提供的商业信息。以上所有内容，受版权、商标、印章和其它财产所有权等相关法律的保护。任何人只能在干纺织授权下才能使用这些内容，但不能复制、再造这些内容、或创造与内容有关的派生产品。
          <h2>法律声明</h2>
          本条款适用中华人民共和国法律。用户和干纺织一致同意服从中华人民共和国司法管辖。若任何干纺织的条款与法律相抵触，那这些条款将按尽可能接近的方法重新解释，而其它条款则保持对用户产生法律效力和影响。
          本条款的不足之处是在用户与干纺织达成服务使用协议时，没有得到用户的书写确认。用户和干纺织一致同意，用户申请服务并完成相应程序，即视为双方达成服务使用协议，双方均受本条款约束。
        </div>
        <div class="row-center mask-btn">
          <div class="text-white bg-blue flex-center pointer" @click="hide">确认</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: false,
    };
  },
  methods: {
    // 显示
    show() {
      this.status = true;
    },
    // 隐藏
    hide() {
      this.status = false;
      this.$emit("hide");
    },
  },
};
</script>
<style lang="less" scoped>
.mask-company {
  width: 1200px;
  background: #ffffff;
  border-radius: 3px;
  padding: 1rem 2rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1003;
  .img-title img {
    width: 21px;
    height: 19px;
    margin-right: 10px;
  }
  .mask-btn {
    justify-content: flex-end;
    div {
      padding: 6px 30px;
      margin-left: 0.875rem;
      font-size: 0.875rem;
    }
    .border-first {
      border: 1px solid #126bf9;
    }
  }
  .user-protocol {
    padding: 10px;
    width: 100%;
    height: 800px;
    line-height: 50px;
    overflow-y: auto;
    div {
      color: #126bf9;
      font-size: 18px;
    }
    div:first-child {
      text-align: center;
      color: #333;
      font-size: 28px;
    }
    p {
      color: #333;
      font-size: 24px;
    }
  }
}
</style>
